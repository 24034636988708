import React, {useCallback, useState} from 'react'
import Modal from '../Modal'
import {AutoColumn} from '../Column'
import styled from 'styled-components'
import {RowBetween} from '../Row'
import {TYPE, CloseIcon} from '../../theme'
import {ButtonError} from '../Button'
import {StakingInfo} from '../../state/stake/hooks'
import {useStakingContract} from '../../hooks/useContract'
import {SubmittedView, LoadingView} from '../ModalViews'
import {TransactionResponse} from '@ethersproject/providers'
import {useTransactionAdder} from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import {useActiveWeb3React} from '../../hooks'
import CurrencyInputPanel from '../CurrencyInputPanel'
import {parseUnits} from "ethers/lib/utils";

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
    isOpen: boolean
    onDismiss: () => void
    stakingInfo: StakingInfo
}

export default function UnstakingModal({isOpen, onDismiss, stakingInfo}: StakingModalProps) {
    const {account} = useActiveWeb3React()
    const [typedValue, setTypedValue] = useState('')
    const onUserInput = useCallback((typedValue: string) => {
        setTypedValue(typedValue)
    }, [])

    // monitor call to help UI loading state
    const addTransaction = useTransactionAdder()
    const [hash, setHash] = useState<string | undefined>()
    const [attempting, setAttempting] = useState(false)

    function wrappedOndismiss() {
        setHash(undefined)
        setAttempting(false)
        onDismiss()
    }

    const maxAmountInput = stakingInfo.stakedAmount
    const setMax = useCallback(() => {
        maxAmountInput && onUserInput(maxAmountInput.toExact())
    }, [maxAmountInput, onUserInput])

    const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

    async function onWithdraw() {
        if (stakingContract && stakingInfo?.stakedAmount) {
            setAttempting(true)
            await stakingContract
                .withdraw(parseUnits(typedValue, stakingInfo.stakedAmount.token.decimals), {gasLimit: 300000})
                .then((response: TransactionResponse) => {
                    addTransaction(response, {
                        summary: `Withdraw deposited liquidity`
                    })
                    setHash(response.hash)
                })
                .catch((error: any) => {
                    setAttempting(false)
                    console.log(error)
                })
        }
    }

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!stakingInfo?.stakedAmount) {
        error = error ?? 'Enter an amount'
    }

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
            {!attempting && !hash && (
                <ContentWrapper gap="lg">
                    <RowBetween>
                        <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
                        <CloseIcon onClick={wrappedOndismiss}/>
                    </RowBetween>
                    {stakingInfo?.stakedAmount && (
                        <AutoColumn justify="center" gap="md">
                            <TYPE.body fontWeight={600} fontSize={36}>
                                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount}/>}
                            </TYPE.body>
                            <TYPE.body>Deposited liquidity:</TYPE.body>
                        </AutoColumn>
                    )}
                    {/*{stakingInfo?.earnedAmount && (*/}
                    {/*  <AutoColumn justify="center" gap="md">*/}
                    {/*    <TYPE.body fontWeight={600} fontSize={36}>*/}
                    {/*      {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}*/}
                    {/*    </TYPE.body>*/}
                    {/*    <TYPE.body>Unclaimed ZyxSwap</TYPE.body>*/}
                    {/*  </AutoColumn>*/}
                    {/*)}*/}
                    <CurrencyInputPanel
                        value={typedValue}
                        onUserInput={onUserInput}
                        showMaxButton={true}
                        onMax={setMax}
                        hideBalance={true}
                        currency={stakingInfo.stakedAmount.token}
                        label={''}
                        disableCurrencySelect={true}
                        customBalanceText={'Available to withdraw: '}
                        id="unstake-liquidity-token"
                    />
                    {!stakingInfo.isNewContract ?
                        <TYPE.subHeader style={{textAlign: 'center'}}>
                            If you withdraw before the end of your staking period, a portion of your ZSWAP is burned
                        </TYPE.subHeader>
                        : null}
                    <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
                        {error ?? 'Withdraw'}
                    </ButtonError>
                </ContentWrapper>
            )}
            {attempting && !hash && (
                <LoadingView onDismiss={wrappedOndismiss}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.body
                            fontSize={20}>Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} ZSWAP</TYPE.body>
                        <TYPE.body
                            fontSize={20}>Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} ZSwap</TYPE.body>
                    </AutoColumn>
                </LoadingView>
            )}
            {hash && (
                <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>Withdrew ZSWAP!</TYPE.body>
                        <TYPE.body fontSize={20}>Claimed ZyxSwap!</TYPE.body>
                    </AutoColumn>
                </SubmittedView>
            )}
        </Modal>
    )
}
