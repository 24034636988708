import React from 'react'
import {AutoColumn} from '../Column'
import {RowBetween} from '../Row'
import styled from 'styled-components'
import {TYPE, StyledInternalLink} from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import {ETHER, JSBI} from '@zyx-exchange/sdk'
import {ButtonPrimary} from '../Button'
import {StakingInfo} from '../../state/stake/hooks'
import {useColor} from '../../hooks/useColor'
import {Break, CardNoise, CardBGImage} from './styled'
import {unwrappedToken} from '../../utils/wrappedCurrency'
import {useToken} from '../../hooks/Tokens'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({theme}) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({showBackground}) => (showBackground ? '1' : '1')};
  background: ${({theme, bgColor, showBackground}) =>
          `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({theme, showBackground}) => (showBackground ? theme.white : theme.text1)} !important;

  ${({showBackground}) =>
          showBackground &&
          `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({theme}) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({showBackground}) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function PoolCard({stakingInfo}: { stakingInfo: StakingInfo }) {
    const token0 = stakingInfo.tokens[0]
    const token1 = stakingInfo.tokens[1]

    const iconToken0 = useToken(stakingInfo.icons[0])
    const iconToken1 = useToken(stakingInfo.icons[1])

    const currency0 = unwrappedToken(token0)

    const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

    // get the color of the token
    const token = currency0 === ETHER ? token1 : token0
    const backgroundColor = useColor(token)

    const isZyx = currency0.name === 'ZYX'

    let coeff = 1
    if (stakingInfo.stakingRewardAddress == '0xFDb4911C943Df778260182bDCff32bd2E6C059c0') {
        coeff = 9.07
    }

    if (stakingInfo.stakingRewardAddress === '0x28a1c8767f83042e8e87D0e8df1e8E3c28C9429f') {
        coeff = 2.29
    }

    return (
        <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
            <CardBGImage desaturate/>
            <CardNoise/>

            <TopSection>
                <DoubleCurrencyLogo currency0={iconToken0} currency1={isZyx ? null : iconToken1} size={24}/>
                <TYPE.white fontWeight={600} fontSize={24} style={{marginLeft: '8px'}}>
                    {!isZyx ? currency0.symbol : token0.symbol}-ZSWAP {stakingInfo.isDoubleContract ? "- ZPAD":null}
                </TYPE.white>

                <StyledInternalLink to={`/farm/${stakingInfo.stakingRewardAddress}`} style={{width: '100%'}}>
                    <ButtonPrimary disabled={stakingInfo.isClosed && !isStaking} padding="8px" borderRadius="8px">
                        {isStaking ? 'Manage' : 'Deposit'}
                    </ButtonPrimary>
                </StyledInternalLink>
            </TopSection>

            <StatContainer>
                <RowBetween>
                    <TYPE.white> Total deposited</TYPE.white>
                    <TYPE.white>{`${stakingInfo.stakedAmount?.toFixed(6)} ${stakingInfo.stakedAmount?.token.name}`}</TYPE.white>
                </RowBetween>
                {stakingInfo.isDoubleContract ?
                    <>
                        <RowBetween>
                            <TYPE.white> Pool rate </TYPE.white>
                            <TYPE.white>
                                {stakingInfo
                                    ? stakingInfo.active
                                        ? `${stakingInfo.totalRewardRate?.toFixed(0, {groupSeparator: ','})} ZSWAP / Week`
                                        : '0 ZSWAP / Week'
                                    : '-'}
                            </TYPE.white>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.white> Pool rate </TYPE.white>
                            <TYPE.white>
                                {stakingInfo
                                    ? stakingInfo.active
                                        ? `${(parseInt(stakingInfo.totalRewardRate?.toFixed(0)) * 100).toLocaleString()} ZPAD / Week`
                                        : '0 ZSWAP / Week'
                                    : '-'}
                            </TYPE.white>
                        </RowBetween>
                    </>
                    : <RowBetween>
                        <TYPE.white> Pool rate </TYPE.white>
                        <TYPE.white>
                            {stakingInfo
                                ? stakingInfo.active
                                    ? `${stakingInfo.totalRewardRate?.toFixed(0, {groupSeparator: ','})} ZSWAP / Week`
                                    : '0 ZSWAP / Week'
                                : '-'}
                        </TYPE.white>
                    </RowBetween>
                }
                {/*<RowBetween>*/}
                {/*  <TYPE.white> Coefficient </TYPE.white>*/}
                {/*  <TYPE.white>*/}
                {/*    {stakingInfo*/}
                {/*        ? stakingInfo.active*/}
                {/*            ? `${stakingInfo.coefficient}`*/}
                {/*            : '-'*/}
                {/*        : '-'}*/}
                {/*  </TYPE.white>*/}
                {/*</RowBetween>*/}
                <RowBetween>
                    <TYPE.white> Supply </TYPE.white>
                    <TYPE.white>
                        {stakingInfo
                            ? stakingInfo.active
                                ? stakingInfo.isNewContract
                                    ? `${stakingInfo.totalStakedAmount?.toFixed(0, {
                                        groupSeparator: ','
                                    })} ${stakingInfo.stakedAmount?.token.name}`
                                    : `${stakingInfo.totalStakedAmount?.toFixed(0, {
                                        groupSeparator: ','
                                    })} / ${stakingInfo.maxSupply?.toFixed(0, {groupSeparator: ','})} ${
                                        stakingInfo.stakedAmount?.token.name
                                    }`
                                : 'MAX'
                            : '-'}
                    </TYPE.white>
                </RowBetween>
                {stakingInfo.isDoubleContract ?
                    <>
                        <RowBetween>
                            <TYPE.white> APY ZSWAP </TYPE.white>
                            <TYPE.white>{stakingInfo ? (stakingInfo.active ? `${stakingInfo.apy} %` : '-') : '-'}</TYPE.white>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.white> APY ZPAD </TYPE.white>
                            <TYPE.white>{stakingInfo ? (stakingInfo.active ? `${stakingInfo.apy*100} %` : '-') : '-'}</TYPE.white>
                        </RowBetween>
                    </>
                    : <RowBetween>
                        <TYPE.white> APY </TYPE.white>
                        <TYPE.white>{stakingInfo ? (stakingInfo.active ? `${stakingInfo.apy} %` : '-') : '-'}</TYPE.white>
                    </RowBetween>
                }
            </StatContainer>

            {isStaking && (
                <>
                    <Break/>
                    <BottomSection showBackground={true}>
                        <TYPE.black color={'white'} fontWeight={500}>
                            <span>Your rate</span>
                        </TYPE.black>

                        <TYPE.black style={{textAlign: 'right'}} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{marginRight: '0.5rem'}}>
                ⚡
              </span>
                            {stakingInfo
                                ? stakingInfo.active
                                    ? `${(
                                        parseFloat(
                                            stakingInfo.rewardRate?.divide(JSBI.BigInt(1000))?.toFixed(4)
                                        ) / coeff
                                    ).toLocaleString()} ZSWAP / Week`
                                    : '0 ZSWAP / Week'
                                : '-'}
                        </TYPE.black>
                    </BottomSection>
                </>
            )}

            {stakingInfo.isDoubleContract ?
                <BottomSection showBackground={true}>
                    <TYPE.black color={'white'} fontWeight={500}>
                        <span>Your rate</span>
                    </TYPE.black>

                    <TYPE.black style={{textAlign: 'right'}} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{marginRight: '0.5rem'}}>
                ⚡
              </span>
                        {stakingInfo
                            ? stakingInfo.active
                                ? `${(
                                    (parseFloat(
                                        stakingInfo.rewardRate?.divide(JSBI.BigInt(1000))?.toFixed(4)
                                    ) / coeff) * 100
                                ).toLocaleString()} ZPAD / Week`
                                : '0 ZPAD / Week'
                            : '-'}
                    </TYPE.black>
                </BottomSection>
                : null}
        </Wrapper>
    )
}


// <BottomSection showBackground={true}>
//     <TYPE.black color={'white'} fontWeight={500}>
//         <span>Your rate</span>
//     </TYPE.black>
//
//     <TYPE.black style={{textAlign: 'right'}} color={'white'} fontWeight={500}>
//               <span role="img" aria-label="wizard-icon" style={{marginRight: '0.5rem'}}>
//                 ⚡
//               </span>
//         {stakingInfo
//             ? stakingInfo.active
//                 ? `${(
//                     (parseFloat(
//                         stakingInfo.rewardRate?.divide(JSBI.BigInt(1000))?.toFixed(4)
//                     ) / coeff)*100
//                 ).toLocaleString()} ZPAD / Week`
//                 : '0 ZPAD / Week'
//             : '-'}
//     </TYPE.black>
// </BottomSection>