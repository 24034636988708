import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount } from '@zyx-exchange/sdk'
import { useEffect, useMemo, useState } from 'react'
import { ZYXSWAP } from '../../constants'
// import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
// import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
// import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { Currency } from '@uniswap/sdk'
import axios from 'axios'

export const STAKING_GENESIS = 1600387200
export const REWARDS_DURATION_DAYS = 60

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    icons: [string, string | undefined]
    stakingRewardAddress: string
    apy: number
    rewardRate: string
    days: number
    isClosed: boolean
    isAPI: boolean
    isNewContract: boolean
    isDoubleContract: boolean
  }[]
} = {
  [ChainId.ZYX]: [
    // {
    //     tokens: [
    //         new Token(ChainId.ZYX, '0xE5f9344806c2D7b16D0E4A46b35e508B94DD8be4', 18, 'ZYX-pZPAD LP', 'ZYX-pZPAD LP'),
    //         ZYXSWAP[ChainId.ZYX]
    //     ],
    //     icons: ['0xeab0696b85b7ae29eeb12b7423e8f743d6588963', '0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656'],
    //     stakingRewardAddress: '0x0e77e892826Fd30f19005EE99f0c42e67aF0869f',
    //     apy: 0,
    //     rewardRate: '116000000000000000000000',
    //     days: 60,
    //     isClosed: false,
    //     isAPI: false,
    //     isNewContract: true,
    //     isDoubleContract: true
    // },
    {
      tokens: [
        new Token(ChainId.ZYX, '0x5a41a3ccaa6f661553e9681db6a3dea4929634c2', 18, 'ZYX-zUSDT LP', 'ZYX-zUSDT LP'),
        new Token(ChainId.ZYX, '0xefcAA73145B5e29eEfc47bcbaeFF9e870Fa6a610', 18, 'zUSDT', 'zUSDT')
      ],
      icons: ['0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', '0xefcAA73145B5e29eEfc47bcbaeFF9e870Fa6a610'],
      stakingRewardAddress: '0x18b29d0bb2Fbb7adDb2626461A3d0C8D28e9BB9e',
      apy: 480,
      rewardRate: '92050000000000000000000',
      days: 270,
      isClosed: false,
      isAPI: true,
      isNewContract: false,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0xadd5f1f1f740d492a72e870e29678c27989570ed', 18, 'ZYX-zUSDC LP', 'ZYX-zUSDC LP'),
        new Token(ChainId.ZYX, '0xb99c32A2DA6766158b4ccF29B26E75DC22606eBD', 18, 'zUSDC', 'zUSDC')
      ],
      icons: ['0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', '0xb99c32A2DA6766158b4ccF29B26E75DC22606eBD'],
      stakingRewardAddress: '0xFCd03550f2f2d317f97569ddD4C370025465d549',
      apy: 480,
      rewardRate: '92050000000000000000000',
      days: 270,
      isClosed: false,
      isAPI: false,
      isNewContract: false,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0xce7612e49dcbeb6e1ea6e8b046bd09565b2bb8bd', 18, 'zUSDT-zUSDC LP', 'zUSDT-zUSDC LP'),
        ZYXSWAP[ChainId.ZYX]
      ],
      icons: ['0xefcAA73145B5e29eEfc47bcbaeFF9e870Fa6a610', '0xb99c32A2DA6766158b4ccF29B26E75DC22606eBD'],
      stakingRewardAddress: '0xcadfe629ff0050366f3a56d2A48346936151c4Db',
      apy: 36,
      rewardRate: '92050000000000000000000',
      days: 270,
      isClosed: false,
      isAPI: false,
      isNewContract: false,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0x1e196328b4f1cd1fbf92e01598e735c3db41d3e1', 18, 'ZYX-tZSWAP LP', 'ZYX-tZSWAP LP'),
        ZYXSWAP[ChainId.ZYX]
      ],
      icons: ['0x0ee136c2f3aa621e1a34f50a86e45118617fff89', '0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656'],
      stakingRewardAddress: '0x63F60FAD050a3f634e9f2990bb6dAA88B02002A8',
      apy: 0,
      rewardRate: '115000000000000000000000',
      days: 365,
      isClosed: false,
      isAPI: false,
      isNewContract: true,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', 18, 'Single ZYX 1 month', 'ZYX'),
        ZYXSWAP[ChainId.ZYX]
      ],
      icons: ['0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', ''],
      stakingRewardAddress: '0xFDb4911C943Df778260182bDCff32bd2E6C059c0',
      apy: 24,
      rewardRate: '38350000000000000000000',
      days: 30,
      isClosed: true,
      isAPI: false,
      isNewContract: false,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', 18, 'Single ZYX 6 month', 'ZYX'),
        ZYXSWAP[ChainId.ZYX]
      ],
      icons: ['0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', ''],
      stakingRewardAddress: '0x28a1c8767f83042e8e87D0e8df1e8E3c28C9429f',
      apy: 240,
      rewardRate: '230130000000000000000000',
      days: 180,
      isClosed: true,
      isAPI: false,
      isNewContract: false,
      isDoubleContract: false
    },
    {
      tokens: [
        new Token(ChainId.ZYX, '0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', 18, 'Single ZYX 12 month', 'ZYX'),
        ZYXSWAP[ChainId.ZYX]
      ],
      icons: ['0xc9E1AEA009B0bAe9141F3dc7523fb42Fd48C8656', ''],
      stakingRewardAddress: '0x2F59cF7287fbD7d9709e20BbbEBe214336DB33f6',
      apy: 480,
      rewardRate: '460000000000000000000000',
      days: 365,
      isClosed: true,
      isAPI: false,
      isNewContract: false,
      isDoubleContract: false
    }
  ]
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // only for double Reward,
  earnedAmount1: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // maximum allowed pool supply
  maxSupply: TokenAmount
  // if pool is active
  active: boolean
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount,
    maxSupply: TokenAmount
  ) => TokenAmount
  apy: number
  icons: [string, string | undefined]
  coefficient: string
  isClosed: boolean
  isNewContract: boolean
  isAPI: boolean
  isDoubleContract: boolean
}

interface PoolAPISchema {
  address: string
  poolLimit: string
  poolReward: string
  stakes: {
    reward: string
    totalStake: string
  }
  totalSupply: string
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(addressToFilterBy?: string | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  // detect if staking is ended
  // const currentBlockTimestamp = useCurrentBlockTimestamp()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            addressToFilterBy === undefined
              ? true
              : addressToFilterBy === null
              ? false
              : addressToFilterBy === stakingRewardInfo.stakingRewardAddress
          ) ?? []
        : [],
    [chainId, addressToFilterBy]
  )

  const zswap = chainId ? ZYXSWAP[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const [poolData, setPoolData] = useState([] as PoolAPISchema[])
  const [filteredPoolData, setFilteredPoolData] = useState([] as PoolAPISchema[])

  useEffect(() => {
    axios
      .post('https://app-info.zyxswap.com/all', { user: account, pools: rewardsAddresses })
      .then(r => {
        console.log(r)
        setPoolData(r.data)
      })
      .catch(console.error)
  }, [chainId, account, rewardsAddresses])

  useEffect(() => {
    setFilteredPoolData(poolData.filter(x => rewardsAddresses.includes(x.address)))
  }, [poolData, rewardsAddresses])

  return useMemo(() => {
    if (!chainId || !zswap) return []

    return filteredPoolData.reduce<StakingInfo[]>((memo, pool) => {
      const localPoolInfo = info.find(x => pool.address === x.stakingRewardAddress)
      if (!localPoolInfo) {
        console.error('Discrepancy in API and hardcoded data')
        console.error('API', filteredPoolData)
        console.error('Hardcoded', info)
        return memo
      }

      // get the LP token
      const tokens = localPoolInfo.tokens
      //const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

      // check for account, if no account set to 0

      const stakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(pool.stakes.totalStake ?? 0))
      const rewardAmount = new TokenAmount(tokens[1], JSBI.BigInt(pool.stakes.reward ?? 0))
      const totalStakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(pool.totalSupply ?? 0))
      const totalRewardRate = new TokenAmount(zswap, JSBI.BigInt(localPoolInfo.rewardRate ?? 0))
      const maxSupply = new TokenAmount(tokens[0], JSBI.BigInt(pool.poolLimit ?? 0))

      const getHypotheticalRewardRate = (
        stakedAmount: TokenAmount,
        totalStakedAmount: TokenAmount,
        totalRewardRate: TokenAmount,
        maxSupply: TokenAmount
      ): TokenAmount => {
        return new TokenAmount(
          tokens[0],
          JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
            ? JSBI.greaterThan(maxSupply.raw, JSBI.BigInt(0))
              ? JSBI.multiply(
                  JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt(1000)), maxSupply.raw),
                  stakedAmount.raw
                )
              : JSBI.multiply(
                  JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt(1000)), totalStakedAmount.raw),
                  stakedAmount.raw
                )
            : JSBI.BigInt(0)
        )
      }

      const individualRewardRate = getHypotheticalRewardRate(
        stakedAmount,
        totalStakedAmount,
        totalRewardRate,
        maxSupply
      )

      const apy = JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
        ? localPoolInfo.apy > 0
          ? localPoolInfo.apy
          : parseInt(
              JSBI.multiply(
                JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt('52')), totalStakedAmount.raw),
                JSBI.BigInt('100')
              ).toString()
            )
        : 1500000

      memo.push({
        stakingRewardAddress: localPoolInfo.stakingRewardAddress,
        tokens: localPoolInfo.tokens,
        earnedAmount: rewardAmount,
        earnedAmount1: rewardAmount,
        rewardRate: individualRewardRate,
        totalRewardRate: totalRewardRate,
        stakedAmount,
        maxSupply: maxSupply,
        totalStakedAmount,
        getHypotheticalRewardRate,
        active: true,
        icons: localPoolInfo.icons,
        apy,
        isClosed: localPoolInfo.isClosed,
        isAPI: localPoolInfo.isAPI,
        isNewContract: localPoolInfo.isNewContract,
        isDoubleContract: localPoolInfo.isDoubleContract,
        coefficient: '1'
      })

      return memo
    }, [])

    // return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
    //   // these two are dependent on account
    //   const balanceState = balances[index]
    //   const earnedAmountState = earnedAmounts[index]
    //
    //   // these get fetched regardless of account
    //   const totalSupplyState = totalSupplies[index]
    //   const maxSupply = maxSupplies[index]
    //   const totalReward = totalRewards[index]
    //
    //   if (
    //     // these may be undefined if not logged in
    //     !balanceState?.loading &&
    //     !earnedAmountState?.loading &&
    //     // always need these
    //     totalSupplyState &&
    //     !totalSupplyState.loading &&
    //     maxSupply &&
    //     !maxSupply.loading &&
    //     totalReward &&
    //     !totalReward.loading
    //   ) {
    //     if (balanceState?.error || earnedAmountState?.error || totalSupplyState.error) {
    //       console.error('Failed to load staking rewards info')
    //       return memo
    //     }
    //
    //     // get the LP token
    //     const tokens = info[index].tokens
    //     //const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))
    //
    //     // check for account, if no account set to 0
    //
    //     const stakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(balanceState?.result?.totalStake ?? 0))
    //     const totalStakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(totalSupplyState.result?.[0]))
    //     const totalRewardRate = new TokenAmount(zswap, JSBI.BigInt(info[index].rewardRate))
    //
    //     const maxSupplyToken = new TokenAmount(tokens[0], JSBI.BigInt(maxSupply?.result?.[0] ?? 0))
    //
    //     const stakesPendingReward = new TokenAmount(tokens[0], JSBI.BigInt(balanceState?.result?.pendingReward ?? 0))
    //     const stakesClaimedReward = new TokenAmount(tokens[0], JSBI.BigInt(balanceState?.result?.claimedReward ?? 0))
    //
    //     const getHypotheticalRewardRate = (
    //       stakedAmount: TokenAmount,
    //       totalStakedAmount: TokenAmount,
    //       totalRewardRate: TokenAmount,
    //       maxSupply: TokenAmount
    //     ): TokenAmount => {
    //       return new TokenAmount(
    //         tokens[0],
    //         JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
    //           ? JSBI.greaterThan(maxSupply.raw, JSBI.BigInt(0))
    //             ? JSBI.multiply(
    //                 JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt(1000)), maxSupply.raw),
    //                 stakedAmount.raw
    //               )
    //             : JSBI.multiply(
    //                 JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt(1000)), totalStakedAmount.raw),
    //                 stakedAmount.raw
    //               )
    //           : JSBI.BigInt(0)
    //       )
    //     }
    //
    //     const individualRewardRate = getHypotheticalRewardRate(
    //       stakedAmount,
    //       totalStakedAmount,
    //       totalRewardRate,
    //       maxSupplyToken
    //     )
    //
    //     const d = info[index].days
    //     const tr = totalReward?.result?.toString() ?? '0'
    //     const trBN = JSBI.BigInt(tr)
    //     const dBN = JSBI.BigInt(d)
    //
    //     const coefficient = !info[index].isNewContract
    //       ? (
    //           parseInt(
    //             JSBI.divide(
    //               JSBI.multiply(JSBI.divide(JSBI.multiply(JSBI.BigInt(10000), trBN), JSBI.BigInt('365')), dBN),
    //               trBN
    //             ).toString()
    //           ) / 10000
    //         ).toString()
    //       : '1'
    //
    //     const earnedAmount = new TokenAmount(tokens[0], JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0))
    //
    //     const apy = JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
    //       ? info[index]?.apy > 0
    //         ? info[index]?.apy
    //         : parseInt(
    //             JSBI.multiply(
    //               JSBI.divide(JSBI.multiply(totalRewardRate.raw, JSBI.BigInt('52')), totalStakedAmount.raw),
    //               JSBI.BigInt('100')
    //             ).toString()
    //           )
    //       : 1500000
    //
    //     memo.push({
    //       stakingRewardAddress: rewardsAddress,
    //       tokens: info[index].tokens,
    //       earnedAmount: info[index].isNewContract
    //         ? earnedAmount
    //         : earnedAmount.add(stakesClaimedReward).add(stakesPendingReward),
    //       earnedAmount1: earnedAmount.add(stakesClaimedReward),
    //       rewardRate: individualRewardRate,
    //       totalRewardRate: totalRewardRate,
    //       stakedAmount: stakedAmount,
    //       maxSupply: maxSupplyToken,
    //       totalStakedAmount: totalStakedAmount,
    //       getHypotheticalRewardRate,
    //       active: true,
    //       coefficient: coefficient,
    //       icons: info[index].icons,
    //       apy: apy,
    //       isClosed: info[index].isClosed,
    //       isAPI: info[index].isAPI,
    //       isNewContract: info[index].isNewContract,
    //       isDoubleContract: info[index].isDoubleContract
    //     })
    //   }
    //   return memo
    // }, [])
  }, [chainId, filteredPoolData, info, zswap])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? ZYXSWAP[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Currency | null | undefined,
  userLiquidityUnstaked: CurrencyAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const token = stakingToken ? stakingToken : undefined

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, token)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
