import React, {useCallback, useEffect, useState} from 'react'
import axios from 'axios'
import {useActiveWeb3React} from './index'

export const useTOS = (isZyx) => {
    const {library, chainId, account} = useActiveWeb3React()
    const [signed, setSigned] = useState(false)

    const ZYX_NODE = 'https://app-info.zyxswap.com'

    const handleSign = useCallback(async () => {
        let msg = "I agree with the early unstake penalties and Terms of Service";
        if (isZyx)
            msg = "I agree with the following Terms of Service and ZYX coins lockup until the farming has finished.";

        const signer = library.getSigner()
        signer
            .signMessage(msg)
            .then(r => {
                axios
                    .post(ZYX_NODE + '/sign', {address: account, sign: r, is_zyx: isZyx})
                    .then(() => setSigned(true))
                    .catch(console.error)
            })
            .catch(e => {
                throw e
            })
    }, [account, library])

    useEffect(() => {
        if (library) {
            axios
                .get(ZYX_NODE + '/isSigned', {params: {address: account, is_zyx: isZyx}})
                .then(r => {
                    if (r.status === 200) {
                        setSigned(true)
                    }
                })
                .catch(console.error)
        }
    }, [account])

    return {onSign: handleSign, signed}
}
