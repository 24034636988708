import React, { useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonPrimary } from '../Button'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const WarningContainer = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  background: rgba(242, 150, 2, 0.05);
  border: 1px solid #f3841e;
  border-radius: 20px;
  overflow: auto;
`

const StyledTable = styled.table`
  margin: 0 auto;
  border-radius: 25px;
  border: 1px solid #565a69;
  padding: 1rem;
  width: 100%;
`

const THead = styled.thead``

const TBody = styled.tbody``

const TR = styled.tr``

const TH = styled.th`
  :nth-child(odd) {
  }
`

const TD = styled.td`
  :nth-child(odd) {
  }

  text-align: center;

  margin-bottom: 0.5rem;
`

interface BurnWarningModalProps {
  isOpen: boolean
  onDismiss: () => void
  onAgreed: () => void
  isZyx: boolean
}

export default function TOSModal({ isOpen, onDismiss, onAgreed, isZyx }: BurnWarningModalProps) {
  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      <ContentWrapper gap="lg">
        <RowBetween>
          <TYPE.mediumHeader>Terms of Service</TYPE.mediumHeader>
          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <RowBetween>
          <WarningContainer>
            <TYPE.body color="red2">
              {!isZyx ? (
                <>
                  By using the staking protocol, you agree to the following Terms of service and token early unstake burning rules.
                </>
              ) : (
                <>
                  By using the staking protocol, you agree to the following Terms of Service and ZYX coins lockup until the farming has finished.
                </>
              )}
            </TYPE.body>
          </WarningContainer>
        </RowBetween>
        <RowBetween>
          {!isZyx ? (
            <StyledTable className="tg">
              <THead>
                <TR>
                  <TH className="tg-0lax">Day</TH>
                  <TH className="tg-0lax">Penalty</TH>
                </TR>
              </THead>
              <TBody>
                <TR>
                  <TD className="tg-0lax">1-30</TD>
                  <TD className="tg-0lax">90%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">31-60</TD>
                  <TD className="tg-0lax">80%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">61-90</TD>
                  <TD className="tg-0lax">70%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">91-120</TD>
                  <TD className="tg-0lax">60%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">121-150</TD>
                  <TD className="tg-0lax">50%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">151-180</TD>
                  <TD className="tg-0lax">40%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">181-210</TD>
                  <TD className="tg-0lax">30%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">211-240</TD>
                  <TD className="tg-0lax">20%</TD>
                </TR>
                <TR>
                  <TD className="tg-0lax">241-270</TD>
                  <TD className="tg-0lax">10%</TD>
                </TR>
              </TBody>
            </StyledTable>
          ) : null}
        </RowBetween>
        <RowBetween>
          <ButtonPrimary onClick={onAgreed} width="200px" m="auto">
            Sign
          </ButtonPrimary>
        </RowBetween>
      </ContentWrapper>
    </Modal>
  )
}
